/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// const { registerLinkResolver } = require('gatsby-source-prismic-graphql');
// const { linkResolver } = require('./src/utils/prismic-configuration');

// registerLinkResolver(linkResolver);

require("prismjs/themes/prism-okaidia.css");
// require("@freshworks/crayons/dist/crayons.js")


// npm install gatsby-remark-prismjs prismjs

const { registerLinkResolver } = require('@prismicio/gatsby-source-prismic-graphql');
const { linkResolver } = require('./src/utils/linkResolver');
 
registerLinkResolver(linkResolver);